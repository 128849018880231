import * as React from "react";
import "./aboutus.css";
// About
const About = (props) => {
  return (
    <div className="container about mt-md-5 mt-0">
      <div className="row">
        <div className="col-md-8 col-12">
          <h6 className="fw-bold">About us</h6>
          <h3 className="fw-bold">
          IWEBCODE is a Perfect<br /> Blend Of Technology And Innovation
          </h3>
        </div>
        <div className="col-md-4 mt-md-0 mt-4 col-12">
          <h6 className="fw-bold">Our services</h6>
          <h5 className="fw-bold">WordPress Development</h5>
          <p>
            <span>Mobile App development</span>
          </p>
          <p>
            <span>React Development</span>
          </p>
          <p>
            <span>Hubspot Development</span>
          </p>
          <p>
            <span>Logo & Branding</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
