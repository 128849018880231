import React, { useEffect } from "react";
import "swiper/css";
import "./carousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import CarouselSlide from "../CarouselSlide/slide";
// Import Swiper styles
import { StaticImage } from "gatsby-plugin-image";

// Carousel
const Carousel = (props) => {
  let clients = [
    {
      image: "../../images/client1.png",
    },
    {
      image: "../../images/client2.png",
    },
    {
      image: "../../images/client3.png",
    },
    {
      image: "../../images/client4.png",
    },
  ];
  useEffect(() => {
    let btnprevious = `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="53"
        height="28"
        viewBox="0 0 53.02 27"
        >
        <rect
            className="qodef-button-line"
            y="13.5"
            width="52.05"
            height="1"
        ></rect>
        <polygon
            className="qodef-button-cap"
            points="51.65 14 38.35 27.3 39.05 28 52.35 14.7 53.05 14 52.35 13.3 39.05 0 38.35 0.7 51.65 14"
        ></polygon>
        </svg>`;

    document.querySelector(".swiper-button-prev").innerHTML = btnprevious;
    document.querySelector(".swiper-button-next").innerHTML = btnprevious;
  }, []);
  return (
    <div className="container ps-xl-0 carousel position-relative">
      <div className="carousel__small">Meet some of our clients</div>
      <div className="carousel__top">
        <h3 className="carousel__title">Our trusted <br className="d-md-none"/> vendors</h3>
        <div className="qodef-m-navigation"></div>
      </div>
      {/* <StaticImage
                src={clients[0].image}
                alt="SunMED Logo"
            ></StaticImage> */}
      <Swiper
        spaceBetween={0}
        loop
        autoplay={true}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={4}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[Navigation]}
        navigation
      >
        <SwiperSlide>
          <CarouselSlide>
            <StaticImage
              width={202}
              height={57}
              src={`../../images/client1.png`}
              alt="SunMED Logo"
            ></StaticImage>
          </CarouselSlide>
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide>
            <StaticImage
              width={202}
              height={47}
              src={`../../images/client2.png`}
              alt="SunMED Logo"
            ></StaticImage>
          </CarouselSlide>
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide>
            <StaticImage
              width={151}
              height={61}
              src={`../../images/client3.png`}
              alt="SunMED Logo"
            ></StaticImage>
          </CarouselSlide>
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide>
            <StaticImage
              width={151}
              height={61}
              src={`../../images/client4.png`}
              alt="SunMED Logo"
            ></StaticImage>
          </CarouselSlide>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
