import * as React from "react"
import './slide.css'

// CarouselSlide
const CarouselSlide = ({children}) => {
  return (
    <div className="carouselSlide">
        <div className="carouselInner">
            {/* <img width="89" height="59" src="https://webon.qodeinteractive.com/wp-content/uploads/2020/08/h1-client-img5.png" className="attachment-full size-full" alt="a" loading="lazy" />
            <p>XAMINE</p> */}
            {children}
        </div>
    </div>
  )
}

export default CarouselSlide
