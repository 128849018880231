import React from 'react'
import './cta.css'

const CTA = () => {
    return (
        <div className='container cta position-relative '>
            <div className="row  align-items-center">
                <div className={'col-12 col-md-8'}>
                    <h2>
                        Get our tips straight to your inbox,
                        and become a better manager.
                    </h2>
                </div>
                <div className={`col-12 col-md-4 mt-3 mt-md-0`}>
                    <a href='#sib-form' className="contactusbtn contactusbtn--cta">
                        Contact Us
                    </a>
                </div>
            </div>
            {/* <div className="cta__bg">

            </div> */}
            <div className="cta__backgroundImage d-none d-lg-block ">
                <img src="https://webon.qodeinteractive.com/wp-content/uploads/2020/09/h9-rev-img-2.png" />
            </div>
        </div>
    )
}

export default CTA
