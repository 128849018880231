import * as React from "react";
import "./webdesign.css";

// webdesign
const Webdesign = (props) => {
  return (
    <div className="container ps-xl-0  webdesign">
      <div className="row  align-items-center position-relative">
        <div
          style={{ order: "1" }}
          className="col-xxl-6  col-xl-6 col-lg-6 col-md-5  webcol__right "
        >
          <div className="hero__small mt-3 mt-md-0  d-lg-block fw-bold">02.</div>
          <div className="web__big fw-bold">WordPress Website Development</div>
          <div className="webcontent  d-lg-block mt-4">
            <div className="webcontent__right">
              WordPress works custom theme and plugin development. It is one of
              the most Responsive and flexible platforms that designs websites
              in a revolutionary way. Because about 60% of the world is using
              WordPress for their businesses.
            </div>
            <div className="webcontent__right mt-4">
              {/* Nunc auctor condimentum nisl arcu volutpat duis sagittis ant quiss
              et Nullam ut dui non sapien.{" "} */}
            </div>
            <a href="#hero" className="readmore mt-4">
              <a className="readmore">Contact us</a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="34px"
                height="16px"
                viewBox="0 0 34.53 16"
                xmlSpace="preserve"
                className="readmore_arrow"
              >
                <rect
                  className="readmore_rect"
                  y="7.6"
                  width="34"
                  height=".4"
                ></rect>
                <g>
                  <path
                    className="nested__path"
                    d="M25.83.7l.7-.7,8,8-.7.71Zm0,14.6,8-8,.71.71-8,8Z"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 mt-5 mt-lg-0  ">
          <img
            className="webdesign__image"
            src="https://webon.qodeinteractive.com/wp-content/uploads/2020/09/h9-img-1.jpg"
          />
          <img
            className="webdesign_bg d-none d-lg-block"
            src="https://webon.qodeinteractive.com/wp-content/uploads/2020/11/h9-img-36.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Webdesign;
