import * as React from "react"
import './hero.css'
import HeroForm from "../HeroForm/heroform"
// hero
const Hero = (props) => {
  return (
    <div id="hero" className="container ps-xl-0  hero">
        <div className="row  align-items-center position-relative">
            <div className="col-xxl-6  col-xl-6 col-lg-5 ps-md-0 pe-xxl-5 ">
                <div className="hero__small d-none d-lg-block fw-bold">
                    01.
                </div>
                <div className="hero__big fw-bold">
                    WordPress a powerful robust feature platform
                </div>
                <div className="delivery d-none d-lg-block mt-4">
                    <div className="delivery__left fw-bold">WordPress</div>
                    <div className="delivery__right">
                     WordPress is everything in itself, like having the best plugins, themes along with flexible options.
                    </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-7 mt-5 mt-lg-0  text-md-end">
                <HeroForm />
            </div>
            <div className="hero__backgroundImage d-none d-lg-block ">
                <img src="https://webon.qodeinteractive.com/wp-content/uploads/2020/09/h9-rev-img-2.png" />
            </div>
            
        </div>
    </div>
  )
}

export default Hero
