import * as React from "react";
import "./video.css";

// Video
const Video = (props) => {
  return (
    <div className="container px-0 position-relative video">
      <span
        className="d-block"
        onClick={(e) => {
          console.log(document.querySelector('.youtube'));
          
          document.querySelector('.youtube').classList.remove('d-none')

          console.log(document.querySelector('.youtube').previousElementSibling);
          
          document.querySelector('.youtube').previousElementSibling.classList.add('d-none')
          document.querySelector('.video').classList.add('vidplay')

          console.log(document.querySelector('.youtube').previousElementSibling.previousElementSibling);
          
          document.querySelector('.youtube').previousElementSibling.previousElementSibling.classList.add('d-none')
          // document.querySelector('.youtube').previousElementSibling.previousElementSibling.previousElementSibling.classList.add('d-none')
          // e.target.classList.add('d-none')
        }}
        // href="https://youtu.be/DwPk1HoS83s"
      >
        <div className="video__imagewrapper">
          <img src="https://webon.qodeinteractive.com/wp-content/uploads/2020/09/h9-img-4.jpg" />
        </div>
        
        <svg className="play_btn"><circle cx="50%" cy="50%" r="45%"></circle></svg>
        <svg
          className="play_btnicon"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 17.804 17.804"
          style={{background:"new 0 0 17.804 17.804"}}
          xmlSpace="preserve"
        >
          <g>
            <g id="c98_play">
              <path d="M2.067,0.043C2.21-0.028,2.372-0.008,2.493,0.085l13.312,8.503c0.094,0.078,0.154,0.191,0.154,0.313    c0,0.12-0.061,0.237-0.154,0.314L2.492,17.717c-0.07,0.057-0.162,0.087-0.25,0.087l-0.176-0.04    c-0.136-0.065-0.222-0.207-0.222-0.361V0.402C1.844,0.25,1.93,0.107,2.067,0.043z" />
            </g>
            <g id="Capa_1_78_"></g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
        <div className="video_text">
          <h6 className="qodef-e-tagline">Video</h6>
          <h1 className="qodef-e-title">Introducing. </h1>
        </div>
      </span>
      <div className="video__bg">
        <img src="https://webon.qodeinteractive.com/wp-content/uploads/2020/11/h9-background-img-15.jpg" />
      </div>
      <div className="youtube d-none">
        <iframe  src="https://www.youtube.com/embed/DwPk1HoS83s?controls=1">
        </iframe>
      </div>
    </div>
  );
};

export default Video;


 
