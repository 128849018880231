import * as React from "react";
import Layout from "../components/Layout/layout";
import Hero from "../components/Hero/hero";
import About from "../components/AboutUs/aboutus";
import Webdesign from "../components/WebDesign/webdesign";
import Carousel from "../components/Carousel/carousel";
import Points from "../components/Points/points";
import Video from "../components/Video/video";
import CTA from "../components/CTA/cta";
import { Helmet } from "react-helmet";

// markup
const IndexPage = (props) => {
  return (
    <Layout>
      <Helmet>
        <title>Best WordPress Development Company | IWEBCODE</title>
        <meta charSet="UTF-8" />
        <meta
          name="description"
          content="WordPress is a user-friendly open-source system that are having extensive plugins, robust templates, and a flexible dashboard."
        ></meta>
        <link rel="canonical" href="https://iwebcode.design/"></link>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Best WordPress Development Company | IWEBCODE"
        ></meta>
        <meta
          property="og:description"
          content="WordPress is a user-friendly open-source system that are having extensive plugins, robust templates, and a flexible dashboard."
        ></meta>
        <meta
          name="keywords"
          content="iwebcode, iwebcodesign, Best WordPress Development Company | IWEBCODE"
        />

        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta property="og:url" content="https://iwebcode.design/" />
        <meta property="og:site_name" content="IWEBCODE" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/IWEBCODE/"
        />
        <meta
          property="article:modified_time"
          content="2021-09-17T13:48:59+00:00"
        />
        <meta
          property="og:image"
          content="https://iwebcode.design/wp-content/uploads/2020/12/iwebcode-favicon.png"
        />
        <meta property="og:image:width" content="104" />
        <meta property="og:image:height" content="123" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@iwebcode_" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="13 minutes" />
        <link
          rel="icon"
          href="https://iwebcode.design/wp-content/uploads/2020/12/fav.png"
          sizes="192x192"
        ></link>
      </Helmet>

      <Hero />
      <About />
      <Webdesign />
      <Carousel />
      <CTA />
      <Points />
      <Video />
      
    </Layout>
  );
};

export default IndexPage;
