import React, { useState } from "react";
import "./heroform.css";
import ClipLoader from "react-spinners/ClipLoader";
import { RefreshToken, subscribe } from "../../utils/contact-form-api";
import { navigate } from "gatsby";

// HeroForm

const HeroForm = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    const body = JSON.stringify({
      email: formDetails.email,
      name: formDetails.name,
      custom_fields: {
        phone: formDetails.phone,
      },
    });
    const data = await subscribe(body, null);

    if (data?.status === 401) {
      const token = await RefreshToken();
      const data = await subscribe(body, token);
      if (data?.status === 400) {
        setError(true);
        setLoading(false);
        return;
      }
    }
    setSuccess(true);
    setLoading(false);
    navigate("/thank-you");
  };

  return (
    <div className="sib-form">
      <div id="sib-form-container" className="sib-form-container">
        <div
          id="sib-container"
          className="sib-container--large sib-container--vertical"
        >
          <form
            id="sib-form"
            onSubmit={handleSubmit}
            name="contact"
            method="POST"
            data-netlify="true"
            className="hero__form text-start "
          >
            <h5 className=" fw-bold">Need a Website ? Contact Us</h5>

            <label className="name">
              {" "}
              Enter your full Name <br />
              <span className="input__wrapper">
                <span className="lnr lnr-pencil"></span>

                <div>
                  <div className="sib-input sib-form-block">
                    <div className="form__entry entry_block">
                      <div className="form__label-row ">
                        <div className="entry__field">
                          <input
                            className="input"
                            maxLength={200}
                            type="text"
                            id="NAME"
                            autoComplete="off"
                            name="NAME"
                            onChange={(e) =>
                              setFormDetails({
                                ...formDetails,
                                name: e.target.value,
                              })
                            }
                            size={40}
                            aria-required="true"
                            aria-invalid="false"
                            required
                            minLength={1}
                          />
                        </div>
                      </div>
                      <label className="entry__error entry__error--primary"></label>
                    </div>
                  </div>
                </div>
              </span>{" "}
            </label>

            <label className="email">
              {" "}
              Your Email <br />
              <span className="input__wrapper">
                <span className="lnr lnr-user"></span>

                <div>
                  <div className="sib-input sib-form-block">
                    <div className="form__entry entry_block">
                      <div className="form__label-row ">
                        <div className="entry__field">
                          <input
                            className="input"
                            id="EMAIL"
                            name="EMAIL"
                            autoComplete="off"
                            data-required="true"
                            required
                            onChange={(e) =>
                              setFormDetails({
                                ...formDetails,
                                email: e.target.value,
                              })
                            }
                            type="email"
                            size={40}
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </div>
                      </div>
                      <label className="entry__error entry__error--primary"></label>
                    </div>
                  </div>
                </div>
              </span>{" "}
            </label>
            <label className="tel">
              {" "}
              Your Phone <br />
              <span className="input__wrapper ">
                <span className="lnr lnr-phone-handset"></span>

                <div>
                  <div className="sib-input sib-form-block">
                    <div className="form__entry entry_block">
                      <div className="form__label-row ">
                        <div className="entry__field">
                          <input
                            className="input"
                            maxLength={200}
                            type="text"
                            id="PHONE"
                            name="PHONE"
                            autoComplete="off"
                            required
                            onChange={(e) =>
                              setFormDetails({
                                ...formDetails,
                                phone: e.target.value,
                              })
                            }
                            minLength={5}
                            size={40}
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </div>
                      </div>
                      <label className="entry__error entry__error--primary"></label>
                    </div>
                  </div>
                </div>
              </span>{" "}
            </label>

            <input type="hidden" name="form-name" value="contact" />

            <input
              type="text"
              name="email_address_check"
              defaultValue=""
              className="input--hidden d-none"
            />
            <input type="hidden" name="locale" defaultValue="en" />
            <input type="hidden" name="html_type" defaultValue="simple" />

            <div>
              <div className="sib-form-block">
                <button
                  className="sib-form-block__button sib-form-block__button-with-loader hero__button"
                  form="sib-form"
                  type="submit"
                >
                  <span className="">
                    {(loading && (
                      <ClipLoader loading={true} size={33} color="#fff" />
                    )) ||
                      "Contact Us"}
                  </span>
                </button>
              </div>
            </div>

            <p className={`mt-3 ${error ? "visible" : "invisible"}`}>
              Invalid credentials
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroForm;
