var base64 = require("base-64");

export const RefreshToken = async () => {
  try {
    const refreshToken = process.env.REFRESH_TOKEN;

    const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

    const response = await fetch(refreshTokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.CLIENT_ID + ":" + process.env.CLIENT_SECRET
          ),
      },
    });
    const data = await response.json();
    return data?.access_token;
  } catch (e) {
    return e;
  }
};

export const subscribe = async (body, token) => {
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : process.env.ACCESS_TOKEN}`,
    };
    const url = `https://api.aweber.com/1.0/accounts/${process.env.ACCOUNT_ID}/lists/${process.env.LIST_ID}/subscribers`;
    const response = await fetch(url, {
      headers: headers,
      method: "POST",
      body: body,
    });
    const data = await response;
    return data;
  } catch (e) {
    return e;
  }
};
