import * as React from "react"
import PointsCard from "../PointsCard/pointcard"
import './points.css'

// Points
const Points = (props) => {
  return (
    <div className="container   points">
        <div className="row  position-relative">
            <div className="col-xxl-4  col-xl-4 col-lg-4  ">
                <div className="points__big fw-bold">
                    We do what we love, and we love what we do.
                </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 mt-4 mt-lg-0 ">
                <div className="container px-0">
                    <div className="row g-0">
                        <PointsCard index={1} heading={"Marketing Platform"} content={"WordPress has proved to be the best CMS as it consists of the essential components that help in marketing ethics."} />
                        <PointsCard index={2} heading={"Great Plugins"} content={"A WordPress feature called APIs lets you build the plugins and later a plugin repository is ready for work."} />
                        <PointsCard index={3} heading={"Application Framework"} content={"WordPress also helps in building apps by providing translations, user management, HTTP requests, databases, and URL routing."} />
                        <PointsCard index={4} heading={"Easy Customization"} content={"Easy Customization is the thing that attracts the customers, which is best for the beginners to give them a chance to try their talent worldwide easily."} />
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Points
