import * as React from "react"
import './pointcard.css'
// PointsCard

const PointsCard = ({heading , content, index}) => {
  return (
    
    <div className="col-xxl-6  col-xl-6 col-lg-6 px-0 px-lg-2 pointsCard ">
        <h6 className="fw-bold">
            0{index}.
        </h6>
        <h5 className="fw-bold">{heading}</h5>
        <p>{content}</p>
        
    </div>
        
  )
}

export default PointsCard
